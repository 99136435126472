import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  citySelectorWrapper: (theme) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 15,
    padding: "25px 0",
  }),

  retrieveButton: {
    marginBottom: 8,
    padding: 10,
    textTransform: "none",
  },

  materialCellRoot: {
    "&.title": {
      fontWeight: 600,
    },
  },

  cellContent: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  formLink: (theme) => ({
    "&.reqired": {
      "&:after": {
        color: theme.palette.error.main,
        content: "'*'",
      },
    },
  }),

  materialPaperRoot: {
    position: "relative",
  },

  progressOverlay: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, .8)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },

  loadPlaceholder: {
    height: "calc(100vh - 60px)",
  },
});
