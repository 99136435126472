import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Select, { SelectComponentProps } from "@ui-kit/Select";
import { createCss } from "./styles";
import { CircularProgress } from "@material-ui/core";
import Typography from "@ui-kit/Typography";
import { GEO_API, Models } from "@services/api";
import { useAppStore } from "@store/AppStore";
import { IParams } from "./types";
import { ROUTES } from "@constants";

const defaultCountry = "US";

interface CountryItem {
  id: string;
  name: string;
}

const countries: CountryItem[] = [
  {
    id: "US",
    name: "United States",
  },
  {
    id: "AUS",
    name: "Australia",
  },
];

const WeekendCareFormsComponent: React.FC = () => {
  const { countryId, jurisdictionId } = useParams<IParams>();
  const history = useHistory();
  const [, { setLoader }] = useAppStore();
  const [forms, setForms] = useState<
    Models.GeoJurisdictionsIndexItemDocument[]
  >([]);
  const [jurisdictionsList, setJurisdictionsList] = useState<
    Models.GeoJurisdictionsIndexItem[]
  >([]);
  const [loading, setLoading] = useState(false);

  const css = createCss();

  useEffect(() => {
    if (!countryId) {
      history.replace({
        pathname: `${ROUTES.REGISTRATION_FORMS}/${defaultCountry}`,
      });
    } else {
      void (async () => {
        setLoader(true);

        const {
          data: { data },
        } = await GEO_API.geoJurisdictionsIndex();

        setJurisdictionsList(data);
        setLoader(false);
      })();
    }
  }, [countryId]);

  useEffect(() => {
    if (jurisdictionId) {
      const selected = jurisdictionsList.find(
        (j: any) => j.slug === jurisdictionId && j.country === countryId,
      );

      if (!isNil(selected)) {
        setForms(selected.documents);
      }
    }
  }, [jurisdictionsList, jurisdictionId]);

  function renderFormCell(form: Models.GeoJurisdictionsIndexItemDocument) {
    return (
      <TableCell css={css.materialCellRoot}>
        <div css={css.cellContent}>
          <a
            css={css.formLink}
            className={clsx({
              required: form.isRequired,
            })}
            target="_blank"
            rel="noreferrer noopener"
            download
            href={form.templateUrl}
          >
            {form.label}
          </a>
        </div>
      </TableCell>
    );
  }

  const changeState: SelectComponentProps<Models.GeoJurisdictionsIndexItem>["onChange"] =
    (event) => {
      const value = event.value.slug;
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        history.replace({
          pathname: `${ROUTES.REGISTRATION_FORMS}/${countryId}/${value}`,
        });
      }, 1000);
    };

  const changeCountry: SelectComponentProps<CountryItem>["onChange"] = (
    event,
  ) => {
    const value = event.value.id;

    if (countryId === value) {
      return;
    }

    setForms([]);

    history.replace({
      pathname: `${ROUTES.REGISTRATION_FORMS}/${value}`,
    });
  };

  if (!jurisdictionsList.length) {
    return <div css={css.loadPlaceholder} />;
  }

  return (
    <div data-test="weekend-care-forms">
      <Typography variant="h4" gutterBottom bolded align="center">
        Parent Pass Service
      </Typography>
      <Typography variant="h6" align="center" paragraph>
        Registration forms
      </Typography>
      <Typography paragraph>
        Per regulatory requirements, registration forms must be on file with the
        center no later than the date of your reservation. These forms are
        required by licensing authorities to help ensure the safe care of your
        child.
      </Typography>
      <Typography color="error">
        New attendees are asked to drop-off required medical, immunization and
        registration forms in advance of their reservation.
      </Typography>
      <div css={css.citySelectorWrapper}>
        <Select
          items={countries}
          value={countryId}
          onChange={changeCountry}
          labelProp="name"
          label="Select your country"
        />
        <Select
          items={orderBy(
            jurisdictionsList
              .filter(
                (j: any) => j.weekendCareSupported && j.country === countryId,
              )
              .map((j) => ({
                ...j,
                id: j.slug,
              })),
            "name",
          )}
          value={jurisdictionId || ""}
          onChange={changeState}
          labelProp="name"
          label="Select your location"
        />
      </div>
      <Paper css={css.materialPaperRoot}>
        {loading && (
          <div css={css.progressOverlay}>
            <CircularProgress />
          </div>
        )}
        {!!forms.length && (
          <Table>
            <TableBody data-test="forms-table-body">
              <TableRow key="required-title">
                <TableCell
                  css={css.materialCellRoot}
                  classes={{
                    root: "required title",
                  }}
                >
                  Required forms
                </TableCell>
              </TableRow>
              {forms
                .filter(
                  (form) =>
                    form.isRequired &&
                    form.type ===
                      Models.GeoJurisdictionsIndexItemDocumentType.Form,
                )
                .map((form) => (
                  <TableRow key={form.templateUrl}>
                    {renderFormCell(form)}
                  </TableRow>
                ))}
              <TableRow key="applicable-title">
                <TableCell
                  css={css.materialCellRoot}
                  classes={{
                    root: "title",
                  }}
                >
                  If applicable
                </TableCell>
              </TableRow>
              {forms
                .filter((form) => !form.isRequired && form.type === "form")
                .map((form) => (
                  <TableRow key={form.templateUrl}>
                    {renderFormCell(form)}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </div>
  );
};

export default WeekendCareFormsComponent;
