import React from "react";
import Helmet from "react-helmet";

import Header from "@components/Header";
import Footer from "@components/FooterNew";
import WeekendCareForms from "@components/WeekendCareForms";
import META from "./meta";

const RegistrationFormsPage: React.FC = () => {
  return (
    <>
      <Helmet {...META} />
      <Header fixed />
      <div
        css={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          css={{
            margin: "0 auto",
            maxWidth: 600,
            padding: "90px 15px 30px",
          }}
        >
          <WeekendCareForms />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default RegistrationFormsPage;
